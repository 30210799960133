import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main";
import Landing from './views/Landing';


function App(props) {
    return (
        <Router>
            <Routes>
                    <Route path="/" element={<Landing/>} />
                    <Route path="/main" element={<Main/>} />
            </Routes>
        </Router>
    )
}

export default App;