import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";



function Landing(props) {
    const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0()

    // console.log("isAuthenticated?",isAuthenticated)
    // console.log("user email",user?.email)
    if (!isAuthenticated) {
        <Navigate replace to="/" />
      }

    return (
        <div>
            <button className="login" onClick={() => loginWithRedirect()}>Login</button>
            {useAuth0().isAuthenticated ? <Navigate replace to="/main" /> : null}
        </div>
    );
}

export default Landing;