import React, { useState, useEffect, useLayoutEffect } from 'react';
import './Logs.css';
import Loading from '../components/Loading';
import { localserver, server_algo_url, admin } from '../Main';
import jp from 'jsonpath';
import { saveAs } from 'file-saver';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StrategyModal from './StrategyModal';
// import CalendarView from './CalendarView';
import Pnlchart from "./Pnlchart"

function OrdersHistory(props) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pnlSum, setPnlSum] = useState(0);
  const [winRate, setWinRate] = useState(0);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [csvData, setCSVData] = useState([]); // Data for CSV download
  const [csvHeaders, setCSVHeaders] = useState([]); // CSV header row
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);
  const [showChart, setshowChart] = useState(false);


  useLayoutEffect(() => {
    console.count('render from Logs');
    const fetchData = () => {
      const route = localserver
        ? 'http://localhost:3005/order_history'
        : `${server_algo_url}/order_history`;
      fetch(route)
        .then((res) => res.json())
        .then((response) => {
          setData(response);
          setLoading(false);
          console.count('fetch /order_history');

          // Prepare CSV data and headers
          const csvData = response.map((item) => [
            item.date,
            item.time,
            item.ticker,
            item.strategy,
            item.signal,
            item.gap,
            item.ep,
            item.sl,
            item.pt,
            item.pnl,
          ]);
          setCSVData(csvData);

          const csvHeaders = [
            'DATE',
            'TIME',
            'SYMBOL',
            'STRATEGY',
            'SIGNAL',
            'GAP',
            '@',
            'SL',
            'PT',
            'PNL',
          ];
          setCSVHeaders(csvHeaders);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    if (filteredData.length > 0) {
      const pnlSum = filteredData
        .map((key) => key.pnl)
        .reduce((prev, next) => prev + next);
      setPnlSum(parseFloat(pnlSum * 100).toFixed(2));

      let wins = jp.query(filteredData, `$..[?(@.pnl >0)]`);
      const winRate = wins.length / filteredData.length;
      setWinRate(parseFloat(winRate * 100).toFixed(2));
      setCount(filteredData.length);
    } else {
      setPnlSum(0);
      setWinRate(0);
      setCount(0);
    }
  }, [filteredData]);

  const filterToday = () => {
    setFilteredData(data.filter((obj) => obj.date === getFormattedDate()));
  };

  const filterYesterday = () => {
    setFilteredData(data.filter((obj) => obj.date === getFormattedDate(-1)));
  };

  const filterLast30 = () => {
    const currentMonthStartDate = getFormattedDate(null, true);
    setFilteredData(
      data.filter(
        (obj) =>
          obj.date >= currentMonthStartDate &&
          obj.date <= getFormattedDate(0)
      )
    );
    console.log('LOOKING BACK AT TRADES HISTORY FROM', currentMonthStartDate);
  };

  const long_red_green = () => {
    setFilteredData(data.filter((obj) => obj.strategy === 'long_red_green'));
  };

  const filterRunner = () => {
    setFilteredData(data.filter((obj) => obj.strategy === 'runner'));
  };

  // const filterMkt = () => {
  //   setFilteredData(data.filter((obj) => obj.strategy === 'mkt7-7'));
  // };

  const mkt_bio = () => {
    setFilteredData(data.filter((obj) => obj.strategy === 'mkt_bio'));
  };

  const backside_short = () => {
    setFilteredData(data.filter((obj) => obj.strategy === 'backside_short'));
  };

  const mkt7_penn = () => {
    setFilteredData(data.filter((obj) => obj.strategy === 'mkt7_penn'));
  };

  const bio_6am = () => {
    setFilteredData(data.filter((obj) => obj.strategy === 'bio_6am'));
  };

  const huge_gap = () => {
    setFilteredData(data.filter((obj) => obj.strategy === 'huge_gap'));
  };

  const last_25 = () => {
    const last100Trades = data.slice(-25);
    setFilteredData(last100Trades);
  };

  const all = () => {
    setFilteredData(data.filter((obj) => obj.pnl !== 0));
  };

  const getFormattedDate = (daysOffset = 0, isMonthStart = false) => {
    let date = new Date();
    date.setDate(date.getDate() + daysOffset);
    let formattedDate = date.toISOString().split('T')[0];
    if (isMonthStart) {
      formattedDate = `${formattedDate.split('-').slice(0, 2).join('-')}-01`;
    }
    return formattedDate;
  };

  const handleDownloadCSV = () => {
    const csvDataArray = [csvHeaders, ...csvData];
    const csvContent = csvDataArray.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'orders_history.csv');
  };

  const handleDateRangeSelect = () => {
    if (startDate && endDate) {
      const formattedEndDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate() + 1
      );

      setFilteredData(
        data.filter(
          (obj) =>
            new Date(obj.date).getTime() >= startDate.getTime() &&
            new Date(obj.date).getTime() < formattedEndDate.getTime()
        )
      );
      setShowDatePicker(false);
    }
  };


  // console.log("pnlValues",pnlValues) 

  // State to store the cumulative list
  const [cumulativeValues, setCumulativeValues] = useState([]);
  const [dateValues, setdateValues] = useState([]);

  useEffect(() => {
    const dateValues = []
    const pnlValues = []
    filteredData.map((i) => {
      const position_usd = 1000 //usd per position

      let pnl_usd = i.pnl * position_usd
      let ep = i.ep  //! not real entry price so not very precise
      if (i.ep === 0) {
        i.ep = 1;
      }
      const roundtrip_comms = (position_usd / ep) * 0.005 //amount of shares bought times roundtrip comms
      pnl_usd = pnl_usd - roundtrip_comms

      pnlValues.push(pnl_usd - Math.abs(pnl_usd) * 0.05) //considering 1k sud per position and 5% for locates and slippage
      dateValues.push(i.date)

      return null

    })

    setdateValues(dateValues)
    // Calculate the cumulative list using reduce
    const cumulated = pnlValues.reduce((acc, value, index) => {
      // If this is the first element, just push it to acc
      if (index === 0) {
        acc.push(value);
      } else {
        // Add the current value to the last cumulative sum and push it
        acc.push(acc[index - 1] + value);
      }
      return acc;
    }, []);

    // Set the result in state
    setCumulativeValues(cumulated);
  }, [filteredData]);


  return (
    <div>
      <div className="main-div">
        <div className="navbar-logs">
          <button onClick={filterToday} className="logs-btn">
            Today
          </button>
          <button onClick={filterYesterday} className="logs-btn">
            Yesterday
          </button>
          <button onClick={filterLast30} className="logs-btn">
            This Month
          </button>
          <button onClick={all} className="logs-btn">
            All
          </button>

          <button onClick={mkt_bio} className="logs-btn strategy">
            Mkt_bio
          </button>

          <button onClick={filterRunner} className="logs-btn strategy">
            Runner
          </button>
          <button onClick={long_red_green} className="logs-btn strategy">
            Longs-R/G
          </button>

          {admin ? (
            <button onClick={backside_short} className="logs-btn strategy">
              backside_short
            </button>
          ) : null}

          {admin ? (
            <button onClick={mkt7_penn} className="logs-btn strategy">
              mkt7_penn
            </button>
          ) : null}

          {admin ? (
            <button onClick={bio_6am} className="logs-btn strategy">
              bio_6am
            </button>
          ) : null}

          {admin ? (
            <button onClick={huge_gap} className="logs-btn strategy">
              huge
            </button>
          ) : null}

          {admin ? (
            <button onClick={last_25} className="logs-btn strategy">
              last_25
            </button>
          ) : null}

          {csvData.length > 0 && (
            <button onClick={handleDownloadCSV} className="logs-btn">
              Download CSV
            </button>
          )}

          <button onClick={() => setShowDatePicker(true)} className="logs-btn">
            Date Range
          </button>

          <button onClick={() => setShowModal(true)} className="logs-btn">
            Filter
          </button>

          <button onClick={() => setShowCalendar(!showCalendar)}>Calendar</button>

          <button onClick={() => setshowChart(!showChart)}>Chart</button>


          <text className="logs-text">
            Orders:{count} | Pnl:{pnlSum}% | WinRate:{winRate}%
          </text>
        </div>
        {showDatePicker && (
          <div className="date-picker-container">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
            />
            <button onClick={handleDateRangeSelect}>Apply</button>
          </div>
        )}

        <div className='strategyChart'>
          {showChart ? <Pnlchart
            dates={dateValues}
            pnlValues={cumulativeValues}
          /> : null}
        </div>

        <table className="table-logs table-one">
          <thead>
            <tr>
              <th>DATE</th>
              <th>TIME</th>
              <th>SYMBOL</th>
              <th>STRATEGY</th>
              <th>SIGNAL</th>
              <th>GAP</th>
              <th>@</th>
              <th>SL</th>
              <th>PT</th>
              <th>PNL</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="10">
                  <div className="spinner">
                    <Loading />
                  </div>
                </td>
              </tr>
            )}
            {!loading && filteredData.length === 0 && (
              <tr>
                <td colSpan="10">No data available.</td>
              </tr>
            )}
            {filteredData.map((item, i) => (
              <tr key={i}>
                <td>{item.date}</td>
                <td>{item.time}</td>
                <td>{item.ticker}</td>
                <td>{item.strategy}</td>
                <td>{item.signal}</td>
                <td>
                  {item.gap.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    style: 'percent',
                  })}
                </td>
                <td>
                  {item.ep.toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                  })}
                </td>
                <td className={item.sl > 0 ? 'red-dig' : '0'}>
                  {item.sl.toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                  })}
                </td>
                <td className={item.pt > 0 ? 'green-dig' : '0'}>
                  {item.pt.toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                  })}
                </td>
                <td
                  className={
                    item.pnl > 0
                      ? 'green-dig'
                      : item.pnl < 0
                        ? 'red-dig'
                        : null
                  }
                >
                  {item.pnl.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    style: 'percent',
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <StrategyModal
          strategies={['mkt7_penn', "mkt7-7", 'bio_6am', "mkt_bio", 'backside_short', "long_red_green", "runner", "huge_gap"]}
          onClose={() => setShowModal(false)}
          data={data}
          onApply={(selectedStrategies, startDate, endDate, filteredData) => { //! have to get all vars or not working
            setFilteredData(filteredData);
          }}
        />
      )}

    </div>
  );
}

export default OrdersHistory;
