import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Example usage
// const dates = ['2024-08-01', '2024-08-02', '2024-08-03', '2024-08-04','2024-08-05','2024-08-06','2024-08-07','2024-08-08','2024-08-09','2024-08-10','2024-08-11','2024-08-12','2024-08-13','2024-08-14'];
// const pnlValues = [100, 150, 120, 180,200,220,180,100,80,200,300,340,240,200];

const LineChart = ({ dates, pnlValues }) => {
  const data = {
    labels: dates, // List of dates
    datasets: [
      {
        label: 'Cumulative PnL',
        data: pnlValues, // List of cumulative PnL values
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: false,
        tension: 0.1, // Controls the smoothness of the curve
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cumulative PnL Over Time',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'PnL USD',
        },
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
};


const Pnlchart = ({dates, pnlValues }) => {
  return (
    <div>
      <LineChart dates={dates} pnlValues={pnlValues} />
    </div>
  );
};

export default Pnlchart;
