import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';


const Icu = () => {
  const [symbol, setSymbol] = useState('');
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [error, setError] = useState('');

  const fetchStockData = async () => {
    try {
      // Simulate fetching live order data for testing purposes (replace with your actual API or data source)
      const liveOrderData = getLiveOrderData(symbol);

      setData(liveOrderData);

      // Update the chart data with new data
      updateChartData(liveOrderData);
    } catch (error) {
      setError('Error fetching data. Please check the symbol and try again.');
    }
  };

  const getLiveOrderData = (symbol) => {
    // Simulate live order data (replace with your actual data retrieval logic)
    const labels = chartData.labels.concat(new Date().toLocaleTimeString());
    const dataset = chartData.datasets[0];
    const newOrder = Math.random() * 1000; // Replace with actual order data
    const newData = dataset.data.concat(newOrder);

    return { labels, datasets: [{ ...dataset, data: newData }] };
  };

  const updateChartData = (liveOrderData) => {
    setChartData(liveOrderData);
  };

  return (
    <div>
      <h1>Play with heatmap, react-heatmap</h1>
      <input
        type="text"
        placeholder="Enter Symbol"
        value={symbol}
        onChange={(e) => setSymbol(e.target.value)}
      />
      <button onClick={fetchStockData}>Submit</button>
      {error && <div>{error}</div>}
      {data && (
        <div>
          <h2>Live Orders in USD:</h2>
          <Line data={chartData} options={{ responsive: true }} />
        </div>
      )}
    </div>
  );
};

export default Icu;
