import React, {useState } from 'react';
import {tjData } from '../Main';

let todaysPnl

function Tj() {
    if (tjData.length > 0) {
        todaysPnl = tjData.map(key => key.pnl).reduce((prev, next) => prev + next)
        todaysPnl = parseFloat(todaysPnl * 100).toFixed(2)
    } else {
        todaysPnl = 0
    }

    return (
        <div>
            <div className='orders-div'>
                <table className="table-entries">
                    <tr>
                        <th>DATE</th>
                        <th>TIME</th>
                        <th>SIDE</th>
                        <th>NAME</th>
                        <th>SETUP</th>
                        <th>@</th>
                        <th>SL</th>
                        <th>PT</th>
                        <th>PNL</th>
                    </tr>

                    {console.log("tjdata exported from main", tjData)}
                    {tjData.length > 0 ? tjData.map((item, i) => (
                        <tr key={i} className={item.side === "invalid" ? "trade-invalid" : "0"}>
                            <td>{item.date}</td>
                            <td>{item.time}</td>
                            <td className={item.side === "prep" ? "trade-prepare" : (item.side === "short" ? "trade-short" : item.side === "bc win" ? "trade-win" : "0")}>{item.side}</td>
                            <td>{item.ticker}</td>
                            <td>{item.strategy}</td>
                            <td>{item.entry.toLocaleString(undefined, { maximumFractionDigits: 3 })}</td>
                            <td className={item.sl > 0 ? "red-dig" : "0"}>{item.sl.toLocaleString(undefined, { maximumFractionDigits: 3 })}</td>
                            <td className={item.pt > 0 ? "green-dig" : "0"}>{item.pt.toLocaleString(undefined, { maximumFractionDigits: 3 })}</td>
                            <td className={item.pnl > 0 ? "green-dig" : item.pnl < 0 ? "red-dig" : "0"}>{item.pnl.toLocaleString(undefined, { maximumFractionDigits: 2, style: 'percent' })}</td>
                        </tr>
                    )) : <td colSpan={13}>NO TRADES </td>}

                </table>

            </div>


        </div>
    );
}

export default Tj;