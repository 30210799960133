import React, { useState, useLayoutEffect, useEffect } from 'react';
import './App.css';
import OrdersHistory from "./components/Logs"
import Tj from "./components/Tj"
import Icu from "./components/Icu"
import jp from 'jsonpath';
import { useAuth0 } from "@auth0/auth0-react";
import Say from "react-say";
import cashRegister from "./cash-register-purchase-87313.mp3";

export let admin = false
export let localserver = false;

export let tjData
export let server_quotes_url
export let server_algo_url
export let tape_url
export let indices_url

server_quotes_url = localserver ? 'https://quotes.tradingbot.es' : 'https://quotes.tradingbot.es';
server_algo_url = localserver ? 'http://localhost:3005' : 'https://alg.tradingbot.es';
tape_url = localserver ? 'http://localhost:3005/tape' : 'https://alg.tradingbot.es/tape';
indices_url= localserver ? 'http://localhost:3005/indices' : 'https://quotes.tradingbot.es/indices';


let min_cumvol = 20000;
let min_gap_runner = -0.05;
let min_gap_mkt_7 = 0.20
let min_prng_mkt_7 = 0.2;
let min_prng_mkt_7_penn = 0.15

let old_values = [];
let value_changed_class;
let added_tickers = [];

let order_sound_list = []
let prep_sound_list = []
let short_sound_list = []
let lost_sound_list = []
let invalid_sound_list = []

let biotechs
let mkt_7s
let longs
let mkt_7penn
let bio6am
let backside_short
let huge_gap

const Sound = async (file) => {
  try {
    const audio = new Audio(file);
    audio.loop = false;
    return await audio.play();
  } catch (error) {
    // Handle the error here, you can log it or perform any other necessary actions.
    console.error("An error occurred with play:", error);
  }
};


const playback = async (speed, msg) => {
  msg.rate = speed;
  return window.speechSynthesis.speak(msg)
}

function Main() {
  let [data, getData] = useState([]);
  let [prevDayRunners, getDataprevDayData] = useState([]);
  let [showOrdersHistory, setShowOrdersHistory] = useState(false);
  let [showTjComponent, setShowTjComponent] = useState(false);
  let [ShowICUComponent, setShowICUComponent] = useState(false);
  const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0()
  const [isActive, setIsActive] = useState(false);
  const [onlyActive, setOnlyActive] = useState(false); // New state variable
  let [tapeData, setTapeData] = useState([]);
  let [indicesData, setindicesData] = useState([]);

  const toggleActive = () => {
    setOnlyActive(!onlyActive);
  };

  if (user?.name === "appletsx@gmail.com") {
    admin = true
  }

  let quotes_refresh_ms = admin ? 1000 : 5000;
  let tj_refresh_ms = admin ? 500 : 1000;
  let min_price = admin ? 0.1 : 0.9;


  // console.log("isAuthenticated?",isAuthenticated)
  // console.log("user email",user?.email)

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      fetchData(`${server_quotes_url}/api`);
    }, quotes_refresh_ms);
    return () => clearInterval(interval);
  }, []);

  const fetchData = (route) => {
    fetch(route)
      .then((res) => res.json())
      .then((response) => {
        getData(response);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      fetchPrevData(`${server_quotes_url}/runners`);
    }, quotes_refresh_ms);
    return () => clearInterval(interval);
  }, []);

  const fetchPrevData = (route) => {
    fetch(route)
      .then((res) => res.json())
      .then((response) => {
        getDataprevDayData(response);
      })
      .catch((error) => {
        console.error('Error fetching previous day data:', error);
      });
  };

  // Function to fetch data from tape_algo_url
  const fetchTapeData = (route) => {
    fetch(route)
      .then((res) => res.json())
      .then((response) => {
        setTapeData(response); // Set the retrieved data to tapeData state
        console.log('Tape data:',tapeData);
      })
      .catch((error) => {
        console.error('Error fetching tape data:', error);
      });
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      fetchTapeData(`${tape_url}`);
    }, quotes_refresh_ms); // Adjust the timing as needed
    return () => clearInterval(interval);
  }, []);

  // Function to fetch data for indices
  const fetchIndicesData = (route) => {
    fetch(route)
      .then((res) => res.json())
      .then((response) => {
        setindicesData(response); // Set the retrieved data to tapeData state
        console.log('indices data:',indicesData);

      })
      .catch((error) => {
        console.error('Error fetching indices data:', error);
      });
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      fetchIndicesData(`${indices_url}`);
    }, quotes_refresh_ms); // Adjust the timing as needed
    return () => clearInterval(interval);
  }, []);


  prevDayRunners = jp.query(prevDayRunners, `$..[?(@.cumvol>${min_cumvol} && @.price >= ${min_price} && @.gap >=${min_gap_runner})]`);
  prevDayRunners = prevDayRunners.map(obj => {
    return { ...obj, pot: 'runner', disabled: false, hidden: false };
  });
  // console.log("Main data",data)

  biotechs = jp.query(data, `$..[?(@.industry === "Biotechnology")]`)
  biotechs = biotechs.map(obj => {
    return { ...obj, pot: 'bio', disabled: false, hidden: false };
  });

  // &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am//
  const bio_industries = ["Bio", "Health", "Drug", "Medic", "Pharma", "Diag"];
  const bio_industries_results = [];
  data.forEach(item => {
    bio_industries.forEach(substring => {
      if (item.industry.includes(substring)) {
        bio_industries_results.push(item);
      }
    });
  });

  // bio6am = jp.query(bio_industries_results, `$..[?(@.gap >= 0.23)]`)
  bio6am = jp.query(data, `$..[?(@.gap >= 0.23)]`)
  bio6am = bio6am.map(obj => {
    return { ...obj, pot: 'bio_6am', disabled: false, hidden: false };
  });
  // &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am// &bio6am//

  // mkt_7s = jp.query(data, `$..[?(@.industry !== "Biotechnology" && @.prev_d_range<${min_prng_mkt_7} && @.cumvol>${min_cumvol} && @.price >= 1 && @.gap >= ${min_gap_mkt_7} && @.industry !== "Shell Companies")]`)
  // mkt_7s = mkt_7s.map(obj => {
  //   return { ...obj, pot: 'mkt_7', disabled: false, hidden: false };
  // });

  mkt_7penn = jp.query(data, `$..[?(@.cumvol>${min_cumvol} && @.price >=0.15 && @.price<1 && @.gap >= ${min_gap_mkt_7} && @.industry !== "Shell Companies")]`)
  mkt_7penn = mkt_7penn.map(obj => {
    return { ...obj, pot: 'mkt7_penn', disabled: false, hidden: false };
  });

  longs = jp.query(data, `$..[?(@.prev_d_range<${0.3} && @.price >= 1.7)]`)
  longs = longs.map(obj => {
    return { ...obj, pot: 'long_red_green', disabled: false, hidden: false };
  });

  backside_short = jp.query(data, `$..[?(@.cumvol>${5000000} && @.gap >= 0.24)]`)
  backside_short = backside_short.map(obj => {
    return { ...obj, pot: 'backside_short', disabled: false, hidden: false };
  });

  huge_gap = jp.query(data, `$..[?(@.cumvol>100000 && @.gap >= 0.50)]`)
  huge_gap = huge_gap.map(obj => {
    return { ...obj, pot: 'huge_gap', disabled: false, hidden: false };
  });

  admin ? data = bio6am.concat(prevDayRunners, biotechs, longs, mkt_7penn, huge_gap) : data = bio6am.concat(prevDayRunners, biotechs, longs)

  data = data.map(obj => {
    return { ...obj, float_rot: obj.cumvol / obj.yhfloat };
  });

  data = jp.query(data, `$..[?(@.cumvol>${min_cumvol})]`);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc',
  });

  const sortData = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const [toggleValues, setToggleValues] = useState(() => {
    const initialToggleValues = {};
    data.forEach((item, i) => {
      initialToggleValues[i] = {
        disabled: item.disabled,
      };
    });
    return initialToggleValues;
  });



  const [invalidatedData, setInvalidatedData] = useState([]);
  const fetchInvalid = async () => {
    try {
      const response = await fetch(`${server_algo_url}/invalidated`);
      const jsonData = await response.json();
      setInvalidatedData(jsonData);
      // console.log('Invalid data:', jsonData); // Print the fetched data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchInvalid();
    // Fetch data every 1 minute (60,000 milliseconds)
    const interval = setInterval(fetchInvalid, 6000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const handleToggleChange = (id, name, pot) => {
    !admin ? console.log("pass..") :
      setToggleValues((prevState) => {
        const newState = {
          ...prevState,
          [id]: !prevState[id],
          name: name,
          pot: pot,
          toggleState: !prevState[id] ? true : false,
          hidden: true,
        };

        // Find the items in the invalidated data with the same ticker and strategy
        // console.log("test invalidatedData", invalidatedData)
        const foundItems = invalidatedData.filter(
          (item) => item.ticker === name && item.strategy === pot
        );

        // Update the toggleState of all found items based on the active status
        foundItems.forEach((foundItem) => {
          foundItem.toggleState = newState[id];
        });

        console.log(newState);
        return newState;
      });
  };

  // useEffect(() => {
  //   // Merge data from tapeData into the existing data
  //   const mergedData = data.map((item) => {
  //     const matchingTapeItem = tapeData.find((tapeItem) => tapeItem.ticker === item.ticker);
  //     if (matchingTapeItem) {
  //       // Merge the properties from tapeData into data for the matching ticker
  //       return { ...item, ...matchingTapeItem };
  //     }
  //     return item;
  //   });
  
  //   // Update the state with the merged data
  //   getData(mergedData);
  // }, [tapeData]);
  

  for (let key in invalidatedData) {
    data.map((i) => {
      let key_pot = i.pot
      let key_ticker = i.ticker
      // let key_toggleState=i.toggleState

      // console.log(key_ticker,key_pot)
      // console.log(invalidatedData[key].ticker,invalidatedData[key].strategy)
      // console.log('toogle in invalid data',invalidatedData[key].disabled)
      if (invalidatedData[key].ticker === key_ticker & invalidatedData[key].pot === key_pot & invalidatedData[key].disabled === true) {
        i.disabled = true

      } else if (invalidatedData[key].ticker === key_ticker & invalidatedData[key].pot === key_pot & invalidatedData[key].disabled === false) {
        i.disabled = false
      }
    })
  }


  useEffect(() => {
    const sendToggleState = async () => {
      console.log("debug", toggleValues)
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(toggleValues),
        };

        const response = await fetch(`${server_algo_url}/controll`, requestOptions);

        console.log(requestOptions);
        if (!response.ok) {
          throw new Error('Request failed');
        }
        // const data = await response.json();
        // Handle the response if needed
      } catch (error) {
        console.error('Error sending toggle state:', error);
      }
    };

    sendToggleState();
  }, [toggleValues, invalidatedData]);


  const sortedData = React.useMemo(() => {
    let filteredData = data;
    if (onlyActive) {
      // Filter data to display only items with disabled: false
      filteredData = data.filter((item) => !item.disabled);
    }
    if (sortConfig.key) {
      const sorted = [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
      return sorted;
    }
    return filteredData;
  }, [data, sortConfig, onlyActive]);

  sortedData.map((item, i) => {
    old_values.map((el, i2) => {
      if (old_values[i2] !== undefined && Object.values(old_values[i2]).includes(item.ticker)) {

        if (item.price > old_values[i2].old_value) {
          value_changed_class = 'changed_up';
          old_values[i2].old_value = item.price;
          sortedData[i].class_change = value_changed_class;
          // console.log('tt', sortedData[i])
        }
        if (item.price < old_values[i2].old_value) {
          value_changed_class = 'changed_down';
          old_values[i2].old_value = item.price;
          sortedData[i].class_change = value_changed_class;
          // console.log('tt', sortedData[i])
        }
      }
    });
    if (added_tickers.includes(item.ticker) === false) {
      old_values.push({ ticker: item.ticker, old_value: item.price });
      added_tickers.push(item.ticker);
    }
  });

  const handleTradeLogClick = () => {
    setShowOrdersHistory(true);
    setShowTjComponent(false);
  };

  const handleSignalsClick = () => {
    setShowOrdersHistory(false);
    setShowTjComponent(true);
    setShowICUComponent(false);
  };

  const handleICUClick = () => {
    setShowOrdersHistory(false);
    setShowTjComponent(false);
    setShowICUComponent(true);
  };

  const handleGappersClick = () => {
    setShowOrdersHistory(false);
    setShowTjComponent(false);
    setShowICUComponent(false);
  };

  //& tj has to be called constantly from main file so signals would come instantlya nd sound alerts wud work
  //& then export tradesData to Tj.js for getting signals into table
  const [tradesData, gettradesData] = useState([]);
  tjData = tradesData

  let trades_url = admin ? `${server_algo_url}/secret_tj` : `${server_algo_url}/tj`
  let tj_key = admin ? "o]P@vNGL|fQuzuQBy+K8z`?bA(M2M3" : "randomkeyxxx12345main"
  // console.log("Concatinated TJs result:",tjData)

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      fetchTj(trades_url);
    }, tj_refresh_ms);
    return () => clearInterval(interval);
  }, []);

  const fetchTj = (route) => {
    fetch(route, {
      headers: new Headers({
        'key': tj_key,
      })
    })
      .then((res) => res.json())
      .then((response) => {
        gettradesData(response);
      })
      .catch((error) => {
        console.log("Error fetching TJ endpoint:", error);
      });
  }

  //& tj has to be called constantly from main file so signals would come instantlya nd sound alerts wud work

  if (!isAuthenticated) {
    loginWithRedirect()
    return (
      <>
      </>
    )
  }

  tradesData?.forEach((el) => {

    if (el.side === "prep" && prep_sound_list.includes(el.ticker) === false) {
      console.log(el.ticker, '-prep sound')
      prep_sound_list.push(el.ticker)
      let message = new SpeechSynthesisUtterance("prepare, " + el.ticker.split('').join(','))
      playback(1.2, message)
    }

    if (el.side === "short" && short_sound_list.includes(el.ticker) == false) {
      console.log(el.ticker, '-short sound')
      short_sound_list.push(el.ticker)
      let message = new SpeechSynthesisUtterance("short, " + el.ticker.split('').join(','))
      playback(1.2, message)
    }

    if (el.side === "bc win" && order_sound_list.includes(el.ticker) == false) {
      console.log(el.ticker, '-cash register sound')
      order_sound_list.push(el.ticker)
      let message = new SpeechSynthesisUtterance(el.ticker.split('').join(','))
      playback(1.2, message)
      Sound(cashRegister)
    }

    if (el.side === "bc loss" && lost_sound_list.includes(el.ticker) == false) {
      console.log('loss sound')
      lost_sound_list.push(el.ticker)
      let message = new SpeechSynthesisUtterance("lost, " + el.ticker.split('').join(','))
      message.rate = 1.2;
      window.speechSynthesis.speak(message)
    }

    if (el.side === "invalid" && invalid_sound_list.includes(el.ticker) === false) {
      console.log('invalid sound')
      invalid_sound_list.push(el.ticker)
      let message = new SpeechSynthesisUtterance("invalid, " + el.ticker.split('').join(','))
      message.rate = 1.2;
      window.speechSynthesis.speak(message)
    }

  })


  return (
    <div className="parent">

      {/* {tradesData?.map((i, index) => (
        <div key={index}>
          {console.log(i.ticker.split('').join('.'))}
          <Say
            pitch={1.0}
            rate={0.9}
            text={i.ticker.split('').join('.') + i.side}
            volume={1}
          />
        </div>
      ))} */}

      <div className="div1">
        <button className="usa-time">{new Date().toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}</button>
        {/* <button className="login" onClick={() => loginWithRedirect()}>Login</button> */}
        <button className="login" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
        {admin ? (
          <>
            <button className="login">redeploy algo</button>
            <button className="login">redeploy quotes</button>
          </>
        ) : null}

        <button className={`login ${onlyActive ? 'active' : ''}`} onClick={toggleActive}>
          {onlyActive ? 'show all' : 'show active'}
        </button>

        <button  className="login">
            Filter
        </button>
        
        <div className='index_container'>
        <span  className="index">
            {` ${indicesData[0]?.ticker } ${((indicesData[0]?.last_trade-indicesData[0]?.pc)/indicesData[0]?.pc).toLocaleString(undefined, {
                        maximumFractionDigits: 3,
                        style: 'percent',
                      })}`}
        </span>
        </div>

      </div>

      <Say
        pitch={1.1}
        rate={1.0}
        text="trading bot started"
        volume={1}
      />
      <div className="div2">
        {showOrdersHistory ? (
          <OrdersHistory />
        ) : showTjComponent ? (
          <Tj />
        ) : ShowICUComponent ? (
          <Icu />
        )
          : (
            <table className="table-screen-two">
              <thead>
                <tr>
                  <th
                    onClick={() => sortData('ticker')}
                    className={sortConfig.key === 'ticker' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    NAME
                  </th>
                  <th
                    onClick={() => sortData('gap')}
                    className={sortConfig.key === 'gap' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    GAP%
                  </th>
                  <th
                    onClick={() => sortData('price')}
                    className={sortConfig.key === 'price' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    PRICE
                  </th>
                  <th
                    onClick={() => sortData('industry')}
                    className={sortConfig.key === 'industry' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    INDUSTRY
                  </th>
                  <th
                    onClick={() => sortData('prev_d_range')}
                    className={
                      sortConfig.key === 'prev_d_range'
                        ? `sorted-${sortConfig.direction}`
                        : ''
                    }
                  >
                    PRV D RNG
                  </th>
                  <th
                    onClick={() => sortData('yhfloat')}
                    className={sortConfig.key === 'yhfloat' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    FLOAT
                  </th>
                  {admin ? <>
                    <th
                      onClick={() => sortData('float_rot')}
                      className={sortConfig.key === 'float_rot' ? `sorted-${sortConfig.direction}` : ''}
                    >
                      F/ROT
                    </th>
                    <th
                      onClick={() => sortData('gappers_count_over25')}
                      className={sortConfig.key === 'gappers_count_over25' ? `sorted-${sortConfig.direction}` : ''}
                    >
                      DELTA
                    </th>
                    <th
                      onClick={() => sortData('gappers_count_over25')}
                      className={sortConfig.key === 'gappers_count_over25' ? `sorted-${sortConfig.direction}` : ''}
                    >
                      OVER25G
                    </th>
                    <th
                      onClick={() => sortData('gappers_count_over25_fin_red')}
                      className={sortConfig.key === 'gappers_count_over25_fin_red' ? `sorted-${sortConfig.direction}` : ''}
                    >
                      FinRed
                    </th>
                  </> : null}
                  <th
                    onClick={() => sortData('workers')}
                    className={sortConfig.key === 'workers' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    PPL
                  </th>
                  <th
                    onClick={() => sortData('cumvol')}
                    className={sortConfig.key === 'cumvol' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    CumVol
                  </th>

                  <th
                    onClick={() => sortData('cumusd')}
                    className={sortConfig.key === 'cumusd' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    CumUsd
                  </th>

                  <th
                    onClick={() => sortData('from_prev_high')}
                    className={
                      sortConfig.key === 'from_prev_high'
                        ? `sorted-${sortConfig.direction}`
                        : ''
                    }
                  >
                    FromH%
                  </th>
                  <th
                    onClick={() => sortData('mktcap')}
                    className={sortConfig.key === 'mktcap' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    MKT CAP
                  </th>
                  <th
                    onClick={() => sortData('io')}
                    className={sortConfig.key === 'io' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    IO
                  </th>
                  <th
                    onClick={() => sortData('strategy')}
                    className={
                      sortConfig.key === 'strategy' ? `sorted-${sortConfig.direction}` : ''
                    }
                  >
                    SETUP
                  </th>
                  <th
                    onClick={() => sortData('country')}
                    className={sortConfig.key === 'country' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    COUNTRY
                  </th>
                  <th
                    onClick={() => sortData('pot')}
                    className={sortConfig.key === 'pot' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    POT
                  </th>
                  <th
                    onClick={() => sortData('disabled')}
                    className={sortConfig.key === 'disabled' ? `sorted-${sortConfig.direction}` : ''}
                  >
                    ON/OFF
                  </th>
                </tr>

              </thead>
              <tbody>
                {sortedData.map((item, i) => (
                  <tr className={item.class_change} key={i}>
                    <td>{item.ticker}</td>
                    <td className={item.class_gap}>
                      {item.gap.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        style: 'percent',
                      })}
                    </td>
                    <td className={item.class_price}>
                      {item.price.toLocaleString(undefined, {
                        maximumFractionDigits: 4,
                      })}
                    </td>
                    <td className={item.class_industry}>{item.industry}</td>
                    <td className={item.class_prev_day}>
                      {item.prev_d_range.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        style: 'percent',
                      })}
                    </td>
                    <td className={item.class_float}>
                      {item.yhfloat.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </td>

                    {admin ?
                      <>
                        <td className={item.float_rot >= 5 && isFinite(item.float_rot) === true ? 'red-dig' : item.float_rot < 0.05 && isFinite(item.float_rot) === true ? "yellow-dig" : "0"}>
                          {item.float_rot.toLocaleString(undefined, {
                            maximumFractionDigits: 3,
                          })}
                        </td>
                        <td className={item.float_rot >= 5 && isFinite(item.float_rot) === true ? 'red-dig' : item.float_rot < 0.05 && isFinite(item.float_rot) === true ? "yellow-dig" : "0"}>
                          {1 - 2} {/* delta between uptick and downtick orders sum  */}
                        </td>
                        <td className={"0"}>
                          {item.gappers_count_over25?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </td>
                        <td className={"0"}>
                          {item.gappers_count_over25_fin_red?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            style: 'percent'
                          })}
                        </td>
                      </> : null}

                    <td>{item.workers.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                    })}</td>

                    <td className={item.class_cumvol}>
                      {item.cumvol.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    
                    <td>
                      {item.cumusd?.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}
                    </td>

                    <td className={item.class_from_high}>
                      {item.from_prev_high.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        style: 'percent',
                      })}
                    </td>
                    <td className={item.mktcap >= 1000000000 ? 'red-dig' : '0'}>
                      {item.mktcap.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}
                    </td>
                    <td
                      className={
                        parseFloat(item.io) > 0.8
                          ? 'red-dig'
                          : parseFloat(item.io) < 0.10
                            ? 'green-dig'
                            : '0'
                      }
                    >
                      {item.io.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        style: 'percent',
                      })}
                    </td>
                    <td
                      className={item.strategy !== '0' ? 'criteria-met' : 'none'}
                      data-hover={
                        item.strategy === '0'
                          ? 'CRITERIA NOT MET'
                          : item.strategy === 'runner'
                            ? '09:45-10:00'
                            : item.strategy === 'mkt7-7'
                              ? '09:25-09:30'
                              : 'none'
                      }
                    >
                      {item.strategy}
                    </td>
                    <td className={`${item.class_kwrd} hovertext`} data-hover={item.news}>
                      {item.country}
                    </td>
                    <td>{item.pot}</td>
                    <td>
                      <div key={i}>
                        <input
                          type="checkbox"
                          id={i}
                          className="toggle-input"
                          checked={item.disabled}
                          onChange={() => handleToggleChange(i, item.ticker, item.pot)}
                        />
                        <label htmlFor={i} className="toggle-label"></label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
      </div>

      <div className="div3">
        <button onClick={handleGappersClick} className='footer-btn'>Strategy Scanner</button>
        <button onClick={handleTradeLogClick} className='footer-btn'>Trade History</button>
        <button onClick={handleSignalsClick} className="footer-btn">Signals</button>
        {admin ? <button className='footer-btn'>Gappers</button> : null}
        {admin ? <button onClick={handleICUClick} className='footer-btn'>I-C-U</button> : null}

        {/* <button className='footer-btn'>Invalidated</button> */}
      </div>
    </div>
  );
}

export default Main;


