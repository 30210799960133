import React, { useState } from 'react';
import Draggable from 'react-draggable';

const StrategyModal = (props) => {
  const { strategies, data, visible, onClose, onApply } = props;
  const [selectedStrategies, setSelectedStrategies] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleCheckboxChange = (strategy) => {
    if (selectedStrategies.includes(strategy)) {
      setSelectedStrategies((prevSelected) =>
        prevSelected.filter((s) => s !== strategy)
      );
    } else {
      setSelectedStrategies((prevSelected) => [...prevSelected, strategy]);
    }
  };



  const handleApply = () => {
    // Convert startDate and endDate to Date objects for accurate comparison
    const startDateObject = startDate ? new Date(startDate) : null;
    const endDateObject = endDate ? new Date(endDate) : null;
    console.log(data)
    // Filter data based on selected strategies and the date range
    const filteredData = data.filter((item) => {
      // Check if the item's strategy is in the selected strategies array
      const isStrategySelected = selectedStrategies.includes(item.strategy);
  
      // Convert the item's date to a Date object
      const itemDateObject = new Date(item.date);
  
      // Check if the item's date is within the specified date range
      const isDateInRange =
        (!startDateObject || itemDateObject >= startDateObject) &&
        (!endDateObject || itemDateObject <= endDateObject);
  
      // Include the item in the filtered data if it meets both criteria
      return isStrategySelected && isDateInRange;
      
    });
    console.log("filteredData",filteredData)
    
  
    // Call the onApply callback with the filtered data
    onApply(selectedStrategies, startDate, endDate, filteredData);
    // onClose();
  };

  const handleTickAll = () => {
    if (selectedStrategies.length === strategies.length) {
      // If all are selected, unselect all
      setSelectedStrategies([]);
    } else {
      // Select all strategies
      setSelectedStrategies([...strategies]);
    }
  };
  
  
  

  return (
    <Draggable handle=".modal-header">
      <div
        className={`modal${visible ? ' visible' : ''}`}
        // style={{ right: visible ? '20px' : '-300px' }}
      >
        <div className="modal-header">
          <span onClick={onClose} className="close-button">
            &times;
          </span>
          <h2 className='modal-h2'>Strategies</h2>
        </div>
        <div className="modal-content">
        <div className="strategy-checkboxes">
  <label>
    <input
      type="checkbox"
      checked={selectedStrategies.length === strategies.length}
      onChange={() => handleTickAll()}
    />
    Tick All
  </label>
  {strategies.map((strategy) => (
    <label key={strategy}>
      <input
        type="checkbox"
        value={strategy}
        checked={selectedStrategies.includes(strategy)}
        onChange={() => handleCheckboxChange(strategy)}
      />
      {strategy}
    </label>
  ))}
</div>
          <div className="date-range">
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
            <label>End Date:</label>
            <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
          </div>
          <button onClick={handleApply}>Apply</button>
        </div>
      </div>
    </Draggable>
  );
};

export default StrategyModal;
